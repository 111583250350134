<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="text-container-group">
        <div class="text-container connect-us">
          <div class="text-header">联系我们</div>
          <div class="text-content">致电：13429252121</div>
          <div class="text-content">邮箱：kezhenhua@nbicc.com</div>
          <div class="text-content">
            地址：宁波新材料国际创新中心A区A7幢15楼
          </div>
          <div class="text-content">
            网站备案：<a href="https://beian.miit.gov.cn/" target="_blank"
              >浙ICP备2022012772号-1</a
            >
          </div>
        </div>
        <div class="text-container kind-link">
          <div class="text-header">友情链接</div>
          <div class="text-content">
            <a href="http://platform.expeedos.cn" target="blank"
              >家电工业互联网平台</a
            >
          </div>
        </div>
        <div class="text-container focus-us">
          <div class="text-header">关注我们</div>
          <img class="qr-img" :src="ercode" alt="" />
        </div>
      </div>
      <div class="footer-bottom">©2022 linkb2m.com 版权所有</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ercode: require("@/assets/img/footer-qr.jpg"),
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/variables.scss";
.footer-container {
  width: 100%;
  height: 264px;
  box-sizing: border-box;
  background-color: #000000;
  border-top: solid 4px $orangeBorder;
  text-align: left;
}

.footer-content {
  width: 1200px;
  height: 100%;
  margin: auto;
  color: #ffffff;
  font-size: 12px;
}

.text-container-group {
  width: 1200px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.text-container {
  height: 200px;
  &.connect-us {
    width: 500px;
  }
  &.kind-link {
    width: 400px;
  }
  &.focus-us {
    width: 300px;
  }
  .text-header {
    font-weight: bold;
    margin: 40px 0 16px 0;
  }
  .text-content,
  .text-content > a {
    box-sizing: border-box;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0 0 12px 0;
    text-decoration: none;
  }
  .qr-img {
    width: 99px;
    height: 102px;
  }
}

.footer-bottom {
  width: 1200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  box-sizing: border-box;
  border-top: solid 1px rgba(255, 255, 255, 0.45);
  color: rgba(255, 255, 255, 0.65);
}
</style>
