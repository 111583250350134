<template>
  <div class="personal-center-container">
    <div class="personal-center-content" v-if="Token === ''">
      <span class="login-btn btn" @click="loginRoute">登录</span>
      <span class="btn"> | </span>
      <span class="registry-btn btn" @click="registerRoute">注册</span>
    </div>
    <div v-else class="personal-center-content">
      <a-popover>
        <template slot="content">
          <p class="select" @click="CheckRoute()">资格认证</p>
          <p class="select" @click="OrderRoute()">我的订单</p>
          <p class="select" @click="EpRoute()">修改密码</p>
        </template>
        <span class="login-btn btn">个人中心</span>
      </a-popover>
      <span class="registry-btn btn" @click="loginOut">退出</span>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("Public");
import Routerpush from "@/mixins/Routerpush";
import LocalCache from "@/utils/cache.js";
import { LoginAPI } from "@/api/login.js";
export default {
  mixins: [Routerpush],
  data() {
    return {};
  },
  computed: {
    ...mapState(["Token"]),
  },
  methods: {
    ...mapMutations(["setToken"]),
    loginOut() {
      let that = this;
      this.$confirm({
        title: "提示",
        content: "是否要退出登录",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          LoginAPI.loginout().then((res) => {
            if (res.success) {
              that.setToken("");
              LocalCache.clearCache();
              sessionStorage.clear();
              that.HomeRoute();
            }

          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.personal-center-container {
  width: 100%;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  color: $fontBlack;
  font-weight: 400;
  font-size: 12px;
  background-color: #eeeeee;
}

.personal-center-content {
  width: 1200px;
  height: 32px;
  margin: auto;
  text-align: right;
}

.select {
  cursor: pointer;
}

.divider-vertical {
  margin: 0 8px;
  vertical-align: middle;
}

.btn {
  margin-left: 10px;
  cursor: pointer;
}
</style>
