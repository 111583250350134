<template>
    <div class="bg">
        <Tips/>
        <!-- 操作栏 -->
        <Operation/>
        <!-- 搜索栏 -->
        <Header/>
        <!-- 不同区域 -->
        <router-view/>
        <!-- 递补栏 -->
        <Footer/>
    </div>
</template>
<script>
import Operation from './components/operation.vue'
import Header from './components/header.vue'
import Footer from './components/footer.vue'
import Tips from '@/components/Tips'
export default {
    data() {
        return {
            
        };
    },

    components:{
        Operation,
        Header,
        Footer,
        Tips
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>
<style lang="scss" scoped>
.bg{background:  #f2f2f2;}
</style>