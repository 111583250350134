<template>
  <transition name="tips">
    <div class="tipsbox" v-if="Token === '' && close">
      <a-icon class="icon" type="exclamation-circle" />
      <p class="font">
        你好！欢迎使用0距离选品平台，<a class="login_font" @click="loginRoute"
          >登录</a
        >进行后，你可以了解更多选品详情。
      </p>
      <a-icon class="close" type="close" @click="closeBtn" />
    </div>
  </transition>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("Public");
import Routerpush from "@/mixins/Routerpush";
export default {
  mixins: [Routerpush],
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          msg: "你好！欢迎使用0距离选品平台，进行后，你可以了解更多选品详情。",
          type: "info",
        };
      },
    },
  },
  data() {
    return {
      close: true,
    };
  },

  computed: {
    ...mapState(["Token"]),
  },

  mounted() {},

  methods: {
    closeBtn() {
      this.close = false;
    },
  },
};
</script>
<style lang="scss" scope>
.tipsbox {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 4px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  display: flex;
  align-items: center;
  .font {
    line-height: 1.5;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .icon {
    color: #1890ff;
  }
  .login_font {
    color: #ff4600;
  }
  .close {
    flex: 1;
    text-align: right;
  }
}
.tips-leave-active {
  animation: fadeOutUp 0.3s;
}
</style>
