<template>
  <div class="search-bar-container">
    <div class="search-bar-content">
      <!-- logo -->
      <img class="logo" :src="logo" alt="" @click="HomeRouteLocal" />

      <!-- 搜索框 -->
      <div class="search-container">
        <div class="search">
          <input
            type="text"
            class="search-input"
            placeholder="请输入关键字"
            allowClear
            v-model="keyWordSearch"
            @keyup.enter="search"
          />
          <button class="search-btn" @click="search">
            <a-icon type="search" class="icon_bg" />
            <span class="search-btn-text">搜索</span>
          </button>
        </div>
        <div class="search-history">
          <!-- 控制超出，由数据计算字符个数，判断最多显示前几个 -->
          <!-- CSS已经做了overflow: hidden -->
          <span
            class="search-history-text"
            v-for="(item, index) in topSearch"
            :key="index"
            @click="clickSearch(item)"
            >{{ item }}</span
          >
        </div>
      </div>

      <!-- 下拉选项 -->
      <a-popover
        :overlayStyle="{ width: '580px' }"
        :visible="clicked"
        placement="bottomRight"
        @visibleChange="handleHoverChange"
      >
        <template slot="content">
          <div class="subject-selector-container">
            <div class="orgTits" @click="getAll()">全部分类</div>
            <div class="selector-body">
              <div class="detial">
                <div
                  class="section"
                  v-for="(item, index) in tree"
                  :key="'tree' + index"
                >
                  <p class="orgTit" @click="getDetail(item, '', 2)">
                    {{ item.label }}
                  </p>
                  <span
                    class="TitTips"
                    v-for="(datas, index) in item.children"
                    :key="'data' + index"
                    @click="getDetail(datas, item, 3)"
                    >{{ datas.label }}</span
                  >
                </div>
              </div>
              <div class="menu">
                <ul>
                  <li
                    v-for="(item, index) in Menu"
                    :key="index"
                    @click="getDetails(item)"
                    @mouseenter="hover(item.id)"
                  >
                    {{ item.label }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <div class="partBtn" @click="RouterList">
          <a-icon type="menu" />
          <span>{{ tips }}</span>
          <a-icon class="downicon" type="down" />
        </div>
      </a-popover>
    </div>
  </div>
</template>
<script>
import { CategoryAPI } from "@/api/Category.js";
import { createNamespacedHelpers } from "vuex";
const { mapMutations } = createNamespacedHelpers("Header");
// import { EventBus } from "@/utils/event-bus.js";
import Routerpush from "@/mixins/Routerpush";
export default {
  mixins: [Routerpush],
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      Menu: [],
      tree: [
        {
          name: "环境调节",
          data: [
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
          ],
        },
        {
          name: "环境调节",
          data: [
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
            "除湿机",
          ],
        },
      ],
      AllTree: [],
      serachkeyWord: "",
      tips: "全部分类",
      clicked: false,
      topSearch: [],
      keyWordSearch: "",
    };
  },
  computed: {
    keyWord: {
      get() {
        return this.$store.state.Header.Search;
      },
      set(val) {
        this.serachkeyWord = val;
      },
    },
  },
  watch: {
    keyWordSearch: {
      deep: true,
      handler(val) {
        // console.log("header", val);
        this.keyWord = val;
        if (val === "") {
          this.change(this.serachkeyWord);
        }
      },
    },
  },
  methods: {
    ...mapMutations(["change", "changePart"]),
    //获取分类
    getCategory() {
      CategoryAPI.getCategory().then((res) => {
        if (res.success) {
          this.Menu = res.data.map((i) => {
            return { label: i.label, id: i.id };
          });
          this.tree = res.data[0].children;
          this.AllTree = res.data;
        }
      });
    },

    //获取topTit
    getTit() {
      CategoryAPI.getKeyWord().then((res) => {
        if (res.success) {
          this.topSearch = res.data.split(",");
        }
      });
    },

    //指针经过
    hover(id) {
      console.log(id);
      this.AllTree.map((i) => {
        if (i.id === id) {
          this.tree = i.children;
        }
      });
    },

    //点击搜索
    search() {
      this.changePart("test");
      this.tips = "全部分类";
      this.change(this.serachkeyWord);
      this.$router.push("/list");
    },

    getAll() {
      this.tips = "全部分类";
      this.change("test");
      this.changePart("");
      this.$router.push("/list");
      this.clicked = false;
    },

    //获取详情
    getDetail(e, parent, type) {
      if (type === 3) {
        this.tips = parent.label + "/" + e.label;
      } else {
        this.tips = "全部分类/" + e.label;
      }
      console.log(e.id);
      this.change("test");
      this.changePart(e.id);
      // EventBus.$emit("changePart", e.id);
      this.$router.push("/list");
      this.clicked = false;
    },

    getDetails(e) {
      this.tips = e.label;
      console.log(e.id);
      this.change("test");
      this.changePart(e.id);
      // EventBus.$emit("changePart", e.id);
      this.$router.push("/list");
      this.clicked = false;
    },

    handleHoverChange(visible) {
      this.clicked = visible;
    },

    clickSearch(e) {
      this.change(e);
      this.$router.push("/list");
    },

    HomeRouteLocal() {
      this.tips = "全部分类";
      this.keyWordSearch = "";
      this.changePart();
      this.$router.push("/");
    },

    RouterList() {
      this.$router.push("/list");
    },
  },
  mounted() {
    this.getCategory();
    this.getTit();
  },
};
</script>
<style scoped lang="scss">
.search-bar-container {
  width: 100%;
  height: 110px;
  background-color: #ffffff;
}

.search-bar-content {
  width: 1200px;
  height: 110px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 190px;
  height: 78px;
  cursor: pointer;
}

.partBtn {
  cursor: pointer;
  & > * {
    margin-right: 5px;
  }
  .downicon {
    margin-left: 10px;
  }
}

.search-container {
  width: 550px;
  .search {
    height: 32px;
    display: flex;
    align-items: center;
    .search-input {
      width: 486px;
      height: 32px;
      box-sizing: border-box;
      border: solid 1px #ff8432;
      border-right: none;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
      padding: 10px 20px;
      font-size: 12px;
      outline: none;
      color: #333333;
    }
    .search-btn {
      width: 64px;
      height: 32px;
      border: none;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      background-image: linear-gradient(to right, #fe8432, #f94639);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
      .search-btn-icon {
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
      .search-btn-text {
        color: #ffffff;
        font-size: 12px;
      }
    }
  }
  .search-history {
    height: 14px;
    width: 550px;
    margin-top: 10px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 12px;
    color: #999999;
    text-align: left;
    .search-history-text {
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.subject-selector-container {
  width: 100%;
  box-sizing: border-box;
  justify-content: right;
  align-items: center;
  position: relative;
  .orgTits {
    color: #fe8432;
    cursor: pointer;
  }

  .selector-body {
    z-index: 100;
    //   width: 580px;
    height: auto;
    //   box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.2);
    display: flex;
    overflow: hidden;
    .detial {
      box-sizing: border-box;
      width: 75%;
      padding: 10px 10px;
      .section {
        margin-bottom: 20px;
      }
      .orgTit {
        color: #fe8432;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .TitTips {
        margin-right: 20px;
        cursor: pointer;
        display: inline-block;
      }
    }
    .menu {
      box-sizing: border-box;
      width: 25%;
      padding: 20px 0px;
      cursor: pointer;
      box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
      ul {
        li {
          height: 40px;
          line-height: 40px;
          padding-left: 10px;
        }
        li:hover {
          box-shadow: 5px 1px 11px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  /deep/ .ant-popover-inner-content {
    padding: 0px 16px !important;
  }
}
</style>
